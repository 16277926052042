<template>
  <div class="header-options">
    <c-link @click="isTeamDrawerOpen = !isTeamDrawerOpen">
      <svg>
        <use href="@/assets/icons/team-fill.svg#team" />
      </svg>
    </c-link>
    <c-box class="notification" mr="5" ml="5">
      <Notification />
    </c-box>
    <c-link mr="4" @click="openSavedPost">
      <c-icon style="height: 23px" color="gray.500" name="bookmark" />
    </c-link>
    <c-popover placement="bottom">
      <c-popover-trigger>
        <c-avatar
          size="sm"
          :name="`${user.firstname} ${user.lastname}`"
          :src="user.profilePhoto"
          borderWidth="0px"
          cursor="pointer"
        >
          <c-avatar-badge size="1.0em" bg="red.400" />
        </c-avatar>
      </c-popover-trigger>
      <c-popover-content w="250px" zIndex="modal">
        <c-popover-body p="0">
          <c-list>
            <template>
              <c-list-item display="flex" borderBottomWidth="1px">
                <c-pseudo-box
                  as="a"
                  @click="$router.push({ name: 'Slack' })"
                  py="2"
                  px="3"
                  w="100%"
                  :_hover="{
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }"
                >
                  <img
                    v-if="!user.slackData"
                    alt="Add to Slack"
                    height="40"
                    width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                  />
                  <c-text v-else>Integrated to Slack</c-text>
                </c-pseudo-box>
                <!-- <a href="https://slack.com/oauth/v2/authorize?client_id=514553026068.2928715089363&scope=app_mentions:read,commands,users.profile:read,chat:write,incoming-webhook&user_scope="></a> -->
              </c-list-item>
            </template>
            <c-list-item
              v-if="isCompanyOwner"
              display="flex"
              borderBottomWidth="1px"
            >
              <c-pseudo-box
                as="a"
                py="2"
                px="3"
                w="100%"
                :_hover="{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }"
                @click="$router.push('/app/overview')"
                >Company Settings</c-pseudo-box
              >
            </c-list-item>
            <c-list-item display="flex" borderBottomWidth="1px">
              <c-pseudo-box
                as="a"
                py="2"
                px="3"
                w="100%"
                :_hover="{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }"
                @click="goToProfile"
                >Profile</c-pseudo-box
              >
            </c-list-item>
            <c-list-item display="flex">
              <c-pseudo-box
                as="a"
                py="2"
                px="3"
                w="100%"
                color="vc-orange.400"
                :_hover="{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }"
                @click="onLogoutClick"
                >Logout (v2.0.9)</c-pseudo-box
              >
            </c-list-item>
          </c-list>
        </c-popover-body>
      </c-popover-content>
    </c-popover>

    <TeamDrawer :isOpen="isTeamDrawerOpen" @close="closeTeamDrawer" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Notification from '@/views/App/components/NotificationDialog.vue';
import TeamDrawer from './TeamDrawer.vue';
import { onLogout } from '@/vue-apollo.js';

export default {
  name: 'HeaderOptions',
  components: {
    Notification,
    TeamDrawer,
  },
  data() {
    return {
      isTeamDrawerOpen: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      companies: (state) => state.companies,
      isShareView: (state) => state.isShareView,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),

    unreadMessageCount() {
      return this.$store.getters.unreadMessageCount;
    },
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
  },
  methods: {
    openSavedPost(){
      this.$router.push('/app/explore/saved-posts')
    },
    async onLogoutClick() {
      localStorage.clear();
      sessionStorage.clear();
      await onLogout(this.$apollo.provider.defaultClient);
      this.$router.push('/auth/login');
    },
    goToProfile() {
        this.$router.push({
          name: 'Profile',
          params: {
            userId: this.user.id,
          },
        });

        // this.$router.go()
    },
    closeTeamDrawer() {
      this.isTeamDrawerOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-options {
  margin-top: 18px;
  display: flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    fill: #8e8e93;
  }

  .pop_content {
    display: block;
    position: fixed !important;
    inset: 0px auto auto 0px;
    margin: 0px;
    // transform: translate3d(44px, 604px, 0px);
    z-index: 999;
  }
}
</style>
