<template>
  <c-flex align="center">
    <c-link @click="isDrawerOpen = true">
      <svg v-chakra w="6" h="6">
        <use href="@/assets/icons/icon-bell.svg#bell"></use>
      </svg>

      <span
        class="online_dot"
        v-if="notifications.length !== 0 || invites.length !== 0"
      ></span>
    </c-link>
    <c-drawer
      :isOpen="isDrawerOpen"
      placement="right"
      :on-close="closeDrawer"
      :initialFocusRef="() => $refs.inputInsideModal"
    >
      <c-drawer-overlay />
      <c-drawer-content>
        <c-drawer-close-button />
        <c-flex mt="20px" align="center" justify="space-between">
          <c-drawer-header>Notifications</c-drawer-header>
          <c-button
            @click="$router.push({ name: 'notifications' })"
            variant="link"
            color="vc-orange"
            size="sm"
            mr="12px"
            >View all</c-button
          >
        </c-flex>

        <c-drawer-body overflowY="scroll">
          <div v-if="notifications.length === 0 && invites.length === 0">
            <c-text fontSize="sm">No notifications</c-text>
          </div>
          <div v-else>
            <p>You have {{ invites.length }} Company Invitations</p>
            <br />
            <c-stack :spacing="4">
              <c-flex align="center" v-for="invite in invites" :key="invite.id">
                <c-avatar
                  size="md"
                  :name="invite.company.name"
                  :src="invite.company.logo"
                  mr="4"
                  borderWidth="0px"
                  color="#fff"
                />
                <c-flex flex="1" justify="space-between" align="center">
                  <c-stack :spacing="1">
                    <c-text fontWeight="500">
                      {{ invite.company.name }}
                    </c-text>

                    <c-text fontWeight="400" fontSize="sm">
                      {{ invite.position }}
                    </c-text>
                  </c-stack>
                </c-flex>
              </c-flex>
            </c-stack>

            <div v-for="notification in notifications" :key="notification.id">
              <div class="notification" @click="goToNotification(notification)">
                <div class="user_name">
                  <img
                    v-if="notification.user.profilePhoto"
                    :src="notification.user.profilePhoto"
                    width="30px"
                  />
                  <span v-else>{{
                    getFirstLetterAndSecond(notification.message)
                  }}</span>
                </div>
                <div class="_message">
                  <div>
                    <c-text fontSize="sm">{{
                      capitalizeFirstLetter(notification.message)
                    }}</c-text>
                    <c-text fontSize="xs">{{
                      notification.createdAt | moment('from')
                    }}</c-text>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    notificationType: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      isDrawerOpen: false,
      pages: [
        {
          name: 'Foundation',
          components: ['values', 'market-goals', 'vision'],
        },
        {
          name: 'BusinessModel',
          components: [
            'overall-problem',
            'overall-solution',
            'overall-value-proposition',
            'customer-segment',
            'channels',
            'key-resources',
            'key-activities',
            'key-partners',
            'revenue',
            'costs',
          ],
        },
        {
          name: 'MarketPotential',
          components: ['go-to-market-strategy', 'overview', 'vision', ''],
        },
        {
          name: 'RiskAssessment',
          components: ['swot', 'pestle', 'vision', 'risk-analysis'],
        },
      ],
    };
  },
  computed: {
    ...mapState('company', {
      notifications: (state) => state.notifications,
    }),
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    invites() {
      return this.user.teams.filter(
        (team) =>
          !team.isAccepted &&
          !team.isDeclined &&
          team.company.createdBy !== this.user.id
      );
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getFirstLetter(string) {
      return string.charAt(0).toUpperCase();
    },
    getFirstLetterAndSecond(string) {
      return string.charAt(0).toUpperCase() + string.charAt(1).toUpperCase();
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    goToNotification(notification) {
      console.log(notification);
      if (notification.data) {
        let type = notification.data.type;
        type = type.replace(/_/g, '-');
        const page = this.pages.find((_page) =>
          _page.components.includes(type)
        );
        if (page) {
          this.$router.push({
            name: page.name,
            query: {
              to_component: type,
            },
          });
          this.closeDrawer();
        }
      }
    },
  },
};
</script>

<style scoped>
.notification {
  width: 100%;
  height: 65px;
  display: flex;
  cursor: pointer;
  padding: 5px;
}

._message {
  display: flex;
}

/* hover bacground color */
.notification:hover {
  background-color: #e9edf4;
  border-color: #e2e8f0;
}

.user_name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: #e2e8f0;
  border-radius: 6px;
  margin-right: 10px;
}

.online_dot {
  height: 13px;
  width: 13px;
  background-color: #e90e0e;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  margin: -30px 0px 0px -4px;
  position: absolute;
}
</style>
